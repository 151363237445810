import React, { ReactElement, useRef } from "react";
import SwiperCore, { Navigation, Scrollbar } from "swiper";
import { SwiperSlide } from "swiper/react";

import PageCard from "components/PageCard";
import Section from "components/Section";
import { useBreakpoint, useScrollbarWidth } from "hooks";
import { BREAKPOINT_VALUE } from "style/breakpoints";
import { addEditAttributesBlockLevel } from "utils/episerver";

import {
    Container,
    Heading,
    HeadingContainer,
    NavigationButton,
    NavigationWrapper,
    Wrapper,
    Slider,
} from "./RelatedContent.styled";
import RelatedContentProps from "./RelatedContentProps";

const RelatedContent = ({
    heading,
    relatedContent,
    identifier,
    contentId,
    anchorName,
    l18n,
}: RelatedContentProps): ReactElement => {
    const sliderRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    let iconSize: number;
    switch (useBreakpoint()) {
        case "XL":
        case "L":
        case "M":
            iconSize = 56;
            break;
        default:
            iconSize = 28;
            break;
    }

    const scrollbarWidth = useScrollbarWidth();

    // Do not render component if there are no items to show
    if (!relatedContent || relatedContent.length === 0) return <></>;

    return (
        <Section
            theme="lightgray"
            alignItems="center"
            id={anchorName}
            {...addEditAttributesBlockLevel(contentId)}
        >
            <HeadingContainer>
                {heading && <Heading>{heading}</Heading>}
                <NavigationWrapper>
                    <NavigationButton
                        ref={navigationPrevRef}
                        iconOnly={true}
                        a11y={true}
                        icon={`bigArrowLeft${iconSize}`}
                        actionTheme={"black"}
                    >
                        {l18n?.previous || "Previous"}
                    </NavigationButton>
                    <NavigationButton
                        ref={navigationNextRef}
                        className="next"
                        iconOnly={true}
                        a11y={true}
                        actionTheme={"black"}
                        icon={`bigArrowRight${iconSize}`}
                    >
                        {l18n?.next || "Next"}
                    </NavigationButton>
                </NavigationWrapper>
            </HeadingContainer>

            <Wrapper>
                <Container
                    key={identifier}
                    $scrollbarWidth={scrollbarWidth || 0}
                >
                    <Slider
                        onInit={(swiper: SwiperCore) => {
                            sliderRef.current = swiper.el;
                        }}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        spaceBetween={28} // Grid spacing
                        slidesPerView={"auto"}
                        loop={false}
                        breakpoints={{
                            [BREAKPOINT_VALUE.XL]: {
                                spaceBetween: 56,
                            },
                            [BREAKPOINT_VALUE.M]: {
                                spaceBetween: 35,
                            },
                        }}
                        scrollbar={{
                            dragSize: "auto",
                            draggable: true,
                        }}
                        modules={[Navigation, Scrollbar]}
                    >
                        {relatedContent?.map((relatedContentItem, index) => (
                            <SwiperSlide key={`slide-${index}`}>
                                <PageCard
                                    key={`card-${index}`}
                                    {...relatedContentItem}
                                    size="small"
                                    gtmClickType="related-news"
                                />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </Container>
            </Wrapper>
        </Section>
    );
};

export default React.memo(RelatedContent);
